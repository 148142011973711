<template>
  <div class="home">
    <headerNav></headerNav>
    <div class="header">
      <div class="header-main flex-i">
        <img class="header-logo" src="../../assets/images/login/logo.png" alt="">
        <span class="header-title">榆阳产业服务平台营销商城</span>
        <div class="header-search-box flex-s-b-i">
          <input class="header-search" style="width: 565px;" v-model="formInline.commodityName" type="text">
          <button class="sch_button" @click="qryGoods">搜索</button>
          <!-- <div class="header-search-btn">搜索</div> -->
        </div>
      </div>
      <!-- <img src="" alt="">
      <span>榆阳产业服务平台营销商城</span> -->
    </div>
    <!-- <img style="margin-top: 20px" src="../../assets/images/index.png" alt=""> -->

    <div class="catalog">
        <div>
            <span>商品分类</span>
            <span>></span>
            <span>{{commodityName}}</span>
            <!-- <span>></span>
            <span>水果</span> -->
        </div>        
    </div>

    <div class="search_tj">
        <div class="search_one" v-for="(item,index) in orderList" :key="index">
            <h5>{{item.propName}}：</h5>
            <ul>
                <li v-for="(it,inx) in item.propValueLists" @click="headleValue(index, inx)" :class="it.type==1?'select_value':'not_value'" :key="inx">{{it.name}}</li>
            </ul>
        </div>
    </div>

    <div class="comprehensive_sequencing">
        <h5 style="margin-right: 60px">综合排序</h5>
        <!-- <a class="conditions">销量</a>
        <a class="conditions">信用</a>
        <a>价格</a> -->

    </div>
    <ul class="guess_goods">
        <li v-for="(item,index) in goodsList" :key="index" style="cursor: pointer" @click="goGoodsDetail(item)">
          <img :src="item.uccCommodityPicRspBOList[0].commodityPicUrl" alt="">
          <p class="by2">{{item.commodityName}}</p>
          <div><span class="price">￥{{item.minPrice/10000}}</span>
          <!-- <p><span class="sheng">广西</span><span class="shi">桂林</span></p> -->
          </div>
        </li>
    </ul>
    <div style="width: 1200px; margin: 0 auto; padding: 32px 0; border-bottom: 1px solid #D8D8D8">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[20, 40, 60, 100]"
          style="float: right"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import headerNav from './../../components/headerNav'
import '../../assets/css/goodsList.scss'
// @ is an alias to /src
export default {
  name: 'home',
  data(){
      return{
        commodityName: this.$route.query.commodityName,
        formInline: {
          commodityName: this.$route.query.name,
          commodityCategoryId: this.$route.query.id,
          endSingleSalePrice: '',
          orderBy: "",
          pageNo: 1,
          pageSize: 10,
          propValues: "",
          startSingleSalePrice: ''
        },
        total: 0,
        // searchContent: this.$route.query.name,
        goodsList: [],
        orderList: []
      }
  },
  
  components: {
    headerNav
  },
  mounted(){
    this.qryGoods();
    this.search();
  },
  methods:{
    qryGoods(){
      if( this.formInline.commodityName != '' ) {
        this.commodityName = this.formInline.commodityName;
        this.formInline.commodityCategoryId = ''
      }
      this.qa.withOutQueryParameterAndValuesByCommodityNameForPC({commodityName: this.commodityName}).then(res => {
        console.log(res);
        for(let i = 0; i < res.data.rows.length; i++) {
          res.data.rows[i].propValueList = res.data.rows[i].propValues.split(",");
          res.data.rows[i].propValueLists = []
          for(let j=0; j<res.data.rows[i].propValueList.length; j++) {
            var obj = {};
            obj.name = res.data.rows[i].propValueList[j];
            obj.type = 0;
            res.data.rows[i].propValueLists.push(obj)
            console.log(obj)
          }
          console.log(res.data.rows[i].propValueLists)
        }
        this.orderList = res.data.rows;
        this.search();
      })
    },
    search(){
      this.qa.withOutqueryCommodityPageListByPC(this.formInline).then(res => {
        this.goodsList = res.data.rows;
        this.total = res.data.recordsTotal;
      })
    },
    headleValue(index, inx) {
      if(this.orderList[index].propValueLists[inx].type == 0) {
        this.orderList[index].propValueLists[inx].type = 1
        console.log(this.orderList[index].propValueLists[inx].type)
      }else {
        this.orderList[index].propValueLists[inx].type = 0
      }
      var arr = []
      for(let i = 0; i < this.orderList.length; i++) {
        for(let j = 0; j < this.orderList[i].propValueLists.length; j++) {
          if(this.orderList[i].propValueLists[j].type == 1) {
            arr.push(this.orderList[i].propValueLists[j].name)
          }
        }
      }
      if(arr.length > 0) {
        if( arr.length == 1) {
          this.formInline.propValues = arr.join('')
        }else {
          this.formInline.propValues = arr.join(',')
        }
      }
      this.search()
    },
    showCatalog(){
      // this.tag = 
    },
    goGoodsDetail(row){
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: row.id
        }
      })
    },
    goList(){
      this.$router.push({
        name: 'goodsList',
        query: {
          name: '1111'
        }
      })      
    },
    handleSizeChange(page){
      this.formInline.pageSize = page;
      this.search();
    },
    handleCurrentChange(page){
      this.formInline.pageNo = page;
      this.search();
    }
  }
}
</script>
<style scoped>
  .home>>>.el-carousel__item img {
    font-size: 18px;
    height: 500px;
    width: 716px;
    margin: 0;
  }
  .home>>>.el-carousel__container {
    width: 716px;
    height: 500px;
  }
  .home>>>.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .home>>>.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .header {
    width: 100%;
    height: 126px;
    background: #fff;
  }
  .header-main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .header-logo {
    width: 60px;
    height: 60px;
  }
  .header-title {
    font-size: 28px;
    color: 333;
    font-weight: 600;
    margin: 0 37px 0 10px;
  }
  .header-search-box {
    width: 718px;
    height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid #84C754;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 21px;
  }
  .header-search {
    border: 0;
    
  }
  .header-search-btn {
    width: 106px;
    height: 44px;
    line-height: 44p[x];
    border: 0 8px 8px 0;
  }
  .sch_button {
    width: 106px;
    height: 46px;
    border: 0;
    margin-right: -1px;
    background: #84C754 !important;
    font-weight: 800;
    color: #FFFFFF;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
  .home>>>button {
      background: transparent;
  }
  .home>>>.number {
      background: transparent;
  }
  .conditions {
    background: url(../../assets/images/garyDown.png) no-repeat right center;
    background-size: 9px 11px;
    padding-right: 18px;
    margin-right: 60px;
  }

  .select_value {
    background:#84C754;
    color: #fff;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 30px;
  }
  .not_value {
    background:#fff;
    color: #000;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 30px;
  }
</style>